import React, { useEffect, useState } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Homepage from './screens/Homepage';
import './index.css';
import { User, getAuth, onAuthStateChanged } from 'firebase/auth';

function App() {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);


  return (
    <div className="App">
      <div className="h-screen w-screen flex justify-center flex-col relative">
        <div className='w-full grow-0'>
          <Header user={user} setUser={setUser} />
        </div>
        <div className='grow overflow-scroll'>
          <Homepage user={user} setUser={setUser} />
        </div>
        <div className="w-screen flex justify-center items-center">
          <a className="text-sm text-[#16161A]" href="https://petplaylist.xyz/">
            Pet Playlist
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;